.activity-img {
    width: 100%;
    height: 250px;
    object-fit: scale-down;
}


@media (min-width: 992px){
    .activity-container{
        max-width: 960px;
    } 
}


.filename{
    font-size: 1rem;
}
body {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#app {
    padding-top: 7em;
}

.glafka-landing {
    .intro-image {
        padding: 18.5rem 0rem;
        background-repeat: no-repeat;
        background: url("https://images.pexels.com/photos/7096/people-woman-coffee-meeting.jpg?cs=srgb&dl=pexels-startup-stock-photos-7096.jpg&fm=jpg");
        background-attachment: scroll;
        background-size: cover;
        background-position: center;
        opacity: 0.35;
        transition: background-position-y .1s;
    }



    .gl-overlay {
        position: absolute;
        left: 18rem;
        top: 11rem;
        width: auto;

        h1 {
            font-size: 4.15rem;
        }

        p {
            max-width: 61%;
        }

        .btn-wh {
            -webkit-text-size-adjust: 100%;
            font-style: normal;
            hyphens: manual;
            -webkit-font-smoothing: subpixel-antialiased;
            visibility: visible;
            -webkit-box-direction: normal;
            box-sizing: border-box;
            fill: #fff;
            line-height: 2;
            display: inline-block;
            max-width: 100%;
            cursor: pointer;
            text-align: center;
            overflow: hidden;
            vertical-align: top;
            white-space: nowrap;
            text-overflow: ellipsis;
            letter-spacing: .6px;
            text-transform: uppercase;
            font-family: Lato, sans-serif;
            font-weight: 700;
            position: static;
            box-shadow: none;
            text-decoration: none;
            border-radius: 0;
            font-size: 16px;
            transition: all .5s !important;
            padding: 10px 49px;
            margin: 0;
            margin-right: 30px;
            border: 2px solid transparent;
            background-color: transparent;
            border-color: #ffffff;
            color: #ffffff;
        }

        .btn-og {
            -webkit-text-size-adjust: 100%;
            font-style: normal;
            hyphens: manual;
            -webkit-font-smoothing: subpixel-antialiased;
            visibility: visible;
            -webkit-box-direction: normal;
            box-sizing: border-box;
            fill: #fff;
            line-height: 2;
            display: inline-block;
            max-width: 100%;
            cursor: pointer;
            text-align: center;
            overflow: hidden;
            vertical-align: top;
            white-space: nowrap;
            text-overflow: ellipsis;
            letter-spacing: .6px;
            text-transform: uppercase;
            font-family: Lato, sans-serif;
            font-weight: 700;
            position: static;
            box-shadow: none;
            text-decoration: none;
            border-radius: 0;
            font-size: 16px;
            transition: all .5s !important;
            padding: 10px 49px;
            margin: 0;
            margin-right: 30px;
            border: 2px solid transparent;
            background-color: $orange;
            color: #292929;
        }


        .sideline-orange::before {
            width: 6px;
            background-color: $orange;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: -15px;
            height: 4rem;
        }

    }

    @media (max-width: 992px) {
        .gl-overlay {
            left: 3.5rem;
            top: 3rem;
        }

        h1 {
            font-size: 3.65rem !important;
        }

        .btn-og {
            margin-top: 1rem;
        }
    }
}



// item list 
.p-list {
    i {
        color: $orange;
        align-items: center;
        font-size: 2.5rem;

        div {
            color: $dark;
            font-size: 1.15rem;
            font-family: Ubuntu;
            font-weight: 700;

        }
    }
}

// Ugly rounded border
// .border-bottom-right {
//     border-bottom-right-radius: 50% 20%;
// }

// @media (min-width: 992px) {
//     .border-upper-left {
//         border-top-left-radius: 50% 20%;
//     }
// }

.text-orange {
    color: $orange;
}

.bg-orange {
    background-color: $orange;
}

.bg-black {
    background-color: black;
}

.bg-white {
    background-color: white;
}

.our-activities {

    // prev orange - glafka, requested darker
    // background: linear-gradient(90deg, #000000 55%, $orange 55%);
    background: linear-gradient(90deg, #000000 55%, #aa5320 55%);

    .sideline-orange::before {
        width: 6px;
        background-color: $orange;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -15px;
        height: 4rem;
    }

    .card {
        border-radius: 0.3 rem;
    }

    .card-body {
        display: grid;
    }

    img {
        width: 100%;
    }
}


.footer {
    margin-top: auto;

    .copy {
        font-size: 12px;
        padding: 10px;
        border-top: 1px solid #FFFFFF;
    }

    .img {
        max-height: 90px;
    }


    .fab {
        padding: 0.5rem;
        font-size: 1.4rem;
        width: 2.3rem;
        height: 2.3rem;
        text-align: center;
        text-decoration: none;
        margin: 5px 2px;
    }

    .fab:hover {
        opacity: 0.7;
    }

    .fa-facebook {
        background: #3B5998;
        color: white;
    }

    .fa-twitter {
        background: #55ACEE;
        color: white;
    }

    .fa-google {
        background: #dd4b39;
        color: white;
    }

    .fa-linkedin {
        background: #007bb5;
        color: white;
    }

    .fa-youtube {
        background: #bb0000;
        color: white;
    }

    .fa-instagram {
        background: #125688;
        color: white;
    }

    // Center for phone display
    @media (max-width: 576px) {

        h4,
        .pt-3,
        .text-right {
            text-align: center !important;
        }

    }
}


.about,
.contact {
    .title-image {
        text-align: center;
        background-color: $orange;
        padding-top: 150px;
        padding-bottom: 150px;

        background: linear-gradient(rgba(242, 118, 46, 0.55), rgb(0 0 0 / 51%)), url(https://images.pexels.com/photos/7096/people-woman-coffee-meeting.jpg?cs=srgb&dl=pexels-startup-stock-photos-7096.jpg&fm=jpg);

        // linear-gradient(rgba($orange, 0.55),
        //         rgba($orange, 0.55)),
        //     url(https://images.pexels.com/photos/7096/people-woman-coffee-meeting.jpg?cs=srgb&dl=pexels-startup-stock-photos-7096.jpg&fm=jpg);
        background-size: cover;
        background-position: left;
    }

    h1 {
        font-size: 50px;
    }


}

.slick-slide img {
    // Our partners slick slider
    height: 100px;
    width: auto !important;
    padding-right: 50px;
    padding-left: 50px;
}

.auth-title {
    // Auth form
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    background-color: rgb(255 255 255 / 0%);
}


.h-sc-102:hover {
    transform: scale(1.02);
}


/* CKEditor edited text fixes */
figure {
    img {
        max-width: 100% !important;
        height: auto;
    }
}

.image_resized {
    height: auto;
}

.image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}

/* END CKEditor */
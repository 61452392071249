.navbar-wrapper {
    border-bottom: 3.5px solid $orange;
    box-shadow: 0 0 15px 8px rgba(0, 0, 0, 0.09);
}

.notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.navbar {
    transition: 0.6s;

    #main-nav-logo {
        height: 75px;
        transition: all 0.3s ease-in;
    }

    a,
    li.a {
        font-size: 1rem;
        color: black;
        position: relative;
        font-weight: bold;
        text-transform: uppercase;
        white-space: nowrap !important;
    }

    a.active:before {

        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $orange;

        transition: all 0.3s ease-in-out;


    }

    .nav-link {
        margin-right: 0.15rem;
        margin-left: 0.15rem;
    }

    .dropdown-menu {
        border: 1px solid $orange;
    }
}


// Undeline hover efect on navbar
.ul-middle {
    // letter-spacing: 0.10em;
    letter-spacing: -0.05em;
}

.ul-middle:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $orange;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

.ul-middle:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.flag {
    line-height: 2rem;
    margin-left: 0.2rem;
}